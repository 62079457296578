import Flickity from 'flickity'
import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

export const selector = '.cards:not([data-paginate="0"])'

export function hydrate (element) {
  const isMobile = browser.is('mobile')

  const groupCells = +(window.getComputedStyle(element).getPropertyValue('--flickity-paginate') ?? 1)
  const flickity = new Flickity(element, {
    cellSelector: '.card',
    groupCells,
    autoPlay: +(element.dataset.autoplay),
    pauseAutoPlayOnHover: false,
    lazyLoad: false,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
    accessibility: true,
    selectedAttraction: isMobile ? 0.8 : 0.05,
    friction: isMobile ? 1 : 0.4,
    dragThreshold: isMobile ? 75 : 3,
    arrowShape: 'M1.121,47.979C1.379,47.356 1.76,46.773 2.266,46.266L44.506,4.026C46.567,1.966 49.913,1.966 51.974,4.026C54.034,6.087 54.034,9.433 51.974,11.494L18.747,44.72L94,44.72C96.914,44.72 99.28,47.086 99.28,50C99.28,52.914 96.914,55.28 94,55.28L18.747,55.28L51.974,88.506C54.034,90.567 54.034,93.913 51.974,95.974C49.913,98.034 46.567,98.034 44.506,95.974L2.266,53.734C1.76,53.227 1.379,52.644 1.121,52.021C0.863,51.398 0.72,50.716 0.72,50C0.72,49.284 0.863,48.602 1.121,47.979Z'
  })

  element.classList.add('is-ready')

  const dots = element.querySelector('.cards__dots')
  if (!dots) return

  const slides = element.querySelectorAll('.card')
  const len = (slides.length / groupCells)
  if (len <= 1) return

  for (let index = 0; index < len; index++) {
    const dot = document.createElement('button')
    dot.classList.add('cta', 'cards__dot')
    dot.classList.toggle('is-active', index === 0)
    dot.innerText = index + 1
    dot.setAttribute('title', `Afficher l’item #${index + 1}`)
    dots.appendChild(dot)

    dot.addEventListener('click', e => {
      e.preventDefault()
      flickity.select(index)
    })
  }

  flickity.on('select', () => {
    dots.querySelectorAll('.cards__dot').forEach((dot, index) => {
      dot.blur()
      dot.classList.toggle('is-active', index === flickity.selectedIndex)
    })
  })
}
